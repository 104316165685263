<template>
    <div class="phone-input" :class="{ error: attrs.error && attrs.error.length }">
        <div class="phone-input__wrap d-flex position-relative">
            <label class="label-input" :for="attrs.name">{{ attrs.label }}</label>
            <div class="phone-input-countries">
                <button type="button" class="phone-input__button" :disabled="countries.length === 0" @click="isOpenCountries = !isOpenCountries">
                    <div class="phone-input__button-inner">
                        <div v-if="countries.length !== 0" class="phone-input__flag" :class="currentCountry.ShortName.toLowerCase()"></div>
                        <span v-if="countries.length !== 0" class="phone-input__button-icon" :class="{ active: isOpenCountries }"> &#9660; </span>
                    </div>
                </button>
                <div v-show="isOpenCountries && countries.length" class="phone-input-countries__wrap">
                    <form>
                        <fieldset>
                            <ul class="phone-input-countries__list">
                                <li
                                    v-for="countryItem in countries"
                                    :key="countryItem.UID"
                                    class="phone-input-countries__item"
                                    :class="{
                                        active: currentCountry.UID === countryItem.UID,
                                    }"
                                >
                                    <div class="d-flex">
                                        <input v-model="currentCountry" :name="countryItem.ShortName" class="input-country-radio" type="radio" :value="countryItem" />
                                    </div>
                                    <label :for="countryItem.ShortName" class="label-country">
                                        <div class="phone-input__flag" :class="countryItem.ShortName.toLowerCase()"></div>
                                        <div class="phone-input__code">+{{ countryItem.PhoneCode.replace('+', '') }}</div>
                                        <div class="phone-input__country-name">
                                            {{ countryItem.Country }}
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="phone-input__number">
                <new-default-input
                    ref="phoneNumberInput"
                    v-model="phoneNumber"
                    v-maska
                    class="phone-input-field"
                    type="text"
                    :name="attrs.name"
                    :data-maska="maskInput"
                    :maxlength="currentCountry.PhoneMasks.length"
                    :placeholder="placeHolder"
                    :disabled="countries.length === 0"
                    @maska="handleChange"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { getPhoneCountries } from '@/api/getPhoneCountries/getPhoneCountries';
import { useCountry } from '~/stores/country';
import NewDefaultInput from '~/components/Input/NewDefaultInput.vue';
import type { CountryItem } from '@/@types/Country';
const sortingCountry = (countriesArray: CountryItem[]): CountryItem[] => {
    return countriesArray.sort((a, b) => {
        const priorityA = a.ShortName === 'KZ' ? -1 : 0;
        const priorityB = b.ShortName === 'KZ' ? -1 : 0;
        const countryA = a.Country.toLowerCase();
        const countryB = b.Country.toLowerCase();
        return priorityA - priorityB || countryA.localeCompare(countryB);
    });
};

const country = useCountry();
const emit = defineEmits(['onValidPhone', 'update:modelValue']);
const phoneNumber = ref('');
const attrs = useAttrs();

const countries = ref<CountryItem[]>([]);
const isOpenCountries = ref(false);
const phoneNumberInput = ref(null);
const validMask = ref(false);

const countriesArray: CountryItem[] = await getPhoneCountries(country.currentCountry).then(res => res.data);
countries.value = sortingCountry(countriesArray);
const currentCountry = ref(countries.value.at(0)); //set first ittem to current
phoneNumber.value = currentCountry.value.PhoneCode; //set mask on render
const maskInput = computed(() => currentCountry.value.PhoneMasks);
const placeHolder = computed(() => currentCountry.value.PhoneMasks.replaceAll('#', '_'));

const handleChange = (data): void => {
    validMask.value = data.detail.completed;
    emit('onValidPhone', validMask.value);
    emit('update:modelValue', '');
};

watch(currentCountry, () => {
    phoneNumber.value = currentCountry.value.PhoneCode;
    isOpenCountries.value = false;
    phoneNumberInput.value.input.focus();
});
watch(validMask, () => {
    if (validMask.value === false) {
        return;
    }
    emit('update:modelValue', phoneNumber.value);
});
</script>

<style  lang="scss" >
@import 'PhoneInput';
</style>
