<template>
    <div class="block-input">
        <label class="label-input" :for="attrs.name">{{ attrs.label }}</label>
        <input v-bind="attrs" ref="input" v-model="value" type="text" class="form-control default-input" :class="{ error: attrs.error && attrs.error.length }" />
    </div>
</template>
<script setup lang="ts">
import { useVModel } from '@vueuse/core';

defineOptions({
    inheritAttrs: false,
});

const attrs = useAttrs();

const props = defineProps<{ modelValue: any }>();
const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>();
const input = ref(null);
const value = useVModel(props, 'modelValue', emit);

defineExpose({ input });
</script>

<style lang="scss" scoped>
.block-input {
    position: relative;
    width: 100%;
    font-family: Roboto;
}
.default-input {
    -webkit-box-shadow: 0 0 0 30px white inset;
    -webkit-text-fill-color: black !important;
    border-radius: 0.1875rem;
    padding: 1.685rem 1rem 0.56rem;
    border: 1px solid var(--main-grey-e-4-e-6-e-7, #e4e6e7);
    background: var(--main-white-ffffff, #fff);
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
    /* secondary/regular/16-20 */
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 125% */
    width: 100%;
    height: 3.5rem;
}
.error {
    border: 1px solid red;
}

.label-input {
    position: absolute;
    top: 0.56rem;
    left: 1rem;
    color: var(--grey-01-aaaaaa, #aaa);
    /* secondary/regular/11-14 */
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.875rem; /* 127.273% */
}
</style>
