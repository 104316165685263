<template>
    <div class="block-input">
        <input v-bind="attrs" v-model="value" type="checkbox" class="default-input" />
        <label v-dompurify-html="attrs.label" class="label-input" :for="attrs.name" />
    </div>
</template>
<script setup lang="ts">
defineOptions({
    inheritAttrs: false,
});
const attrs = useAttrs();

import { useVModel } from '@vueuse/core';
const props = defineProps<{ modelValue: Boolean }>();
const emit = defineEmits<{ (e: 'update:modelValue', value: Boolean): void }>();
const value = useVModel(props, 'modelValue', emit);
</script>

<style lang="scss" scoped>
.block-input {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    width: 100%;
    align-items: flex-start;
    font-family: Roboto;
}
.default-input {
    appearance: none;
    cursor: pointer;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    padding: 0.125rem;
    border-radius: 6px;
    border: 1px solid var(--grey-01-aaaaaa, #aaa);
    background: var(--main-white-ffffff, #fff);
    cursor: pointer;
    position: relative;
    vertical-align: middle; /* Добавляем это свойство */
    &:checked {
        background: transparent;
    }
}
.default-input::before {
    content: url('/icon/checkbox.svg');
    position: absolute;
    top: 63%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-size: 1rem;
    display: none;
}

.default-input:checked::before {
    display: block;
}

.label-input {
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
    font-size: 0.875rem;
    line-height: 1.25rem;
    position: unset;
}
</style>
