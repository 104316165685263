import httpCommon from '@/api/httpCommon/httpCommon';

/**
 * Asynchronously sends contact information to the 'send_email' endpoint.
 * This function uses a POST request to submit the provided data.
 * @param {object} data - The contact information to be sent. This should include all necessary fields required by the 'send_email' endpoint.
 * @returns {Promise<AxiosResponse>} A promise that resolves to the response from the HTTP POST request.
 */
export const sendContact = async data => {
    return await httpCommon().post('send_email', data);
};
