import httpCommon from '@/api/httpCommon/httpCommon';
import type { CountryResponse } from '~/@types/Country';

/**
 * Asynchronously fetches information about phone countries based on the specified language.
 * Sends a GET request using the configured Axios instance from httpCommon.
 * @param {string} lang - The language code to specify the language for the request.
 * @returns {Promise<CountryResponse>} A promise that resolves to the Axios response object containing phone country information.
 */
export const getPhoneCountries = async (lang: string): Promise<CountryResponse> => {
    return await httpCommon().get('countries/phone-info', {
        headers: {
            locale: lang,
        },
    });
};
